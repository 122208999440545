import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import React from 'react';
import Button from '../Button';
import FloorBounty from './FloorBounty';

const FloorBounties = () => {
  const { data } = useQuery({
    queryKey: ['bounties'],
    queryFn: () => fetch('/api/bounties/list').then((res) => res.json()),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
  });
  const order = ['First Litter', 'Second Litter', 'Third Litter', 'Fourth Litter', 'Shitty Cities'];
  const sorted = data?.sort((a, b) => order.indexOf(a.collection) - order.indexOf(b.collection));
  const docsLink = 'https://docs.shittykitties.art/website-utilities/shitty-bounties';

  return (
    <div className='bg-gray-800'>
      <div className={clsx('px-4 mx-auto lg:px-8 max-w-7xl')}>
        <div className='flex flex-col md:flex-row md:justify-between gap-4'>
          <div>
            <h2 className='text-2xl font-extrabold text-neutral-300 md:text-4xl'>Shitty Bounties</h2>
            <p className='mt-4 text-lg text-neutral-400'>Sweep the floor and get shitty rewards!</p>
          </div>
          <div className='flex md:justify-end lg:items-end'>
            <Button theme='none' href={docsLink} target='_blank' className='flex items-center gap-3 text-white'>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' className='w-[20px]'>
                <path
                  d='M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM296 336h-16V248C280 234.8 269.3 224 256 224H224C210.8 224 200 234.8 200 248S210.8 272 224 272h8v64h-16C202.8 336 192 346.8 192 360S202.8 384 216 384h80c13.25 0 24-10.75 24-24S309.3 336 296 336zM256 192c17.67 0 32-14.33 32-32c0-17.67-14.33-32-32-32S224 142.3 224 160C224 177.7 238.3 192 256 192z'
                  fill='currentColor'
                />
              </svg>
              <span className='md:text-lg font-bold'>How does it work?</span>
            </Button>
          </div>
        </div>
      </div>

      <div className='mx-auto md:px-4 lg:px-8 max-w-7xl'>
        <div className='mt-8 flex md:grid md:grid-cols-3 lg:grid-cols-5 md:gap-5 overflow-scroll md:overflow-visible pb-3 md:pb-0'>
          {sorted?.map((bounty) => (
            <FloorBounty key={bounty.name} bounty={bounty} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FloorBounties;
