import Collections from '../components/home/Collections';
import HomeHero from '../components/home/HomeHero';
import TopSales from '../components/home/TopSales';
import UtilityInfo from '../components/home/UtilityInfo';
import ShittyStickersCta from '../components/ShittyStickersCta';
import TopUsersFeed from '../components/TopUsersFeed';
import FloorBounties from '../components/home/FloorBounties';

export default function Home() {
  return (
    <>
      <HomeHero />
      <div className='bg-gray-800 flex flex-col gap-24 pt-24 pb-48'>
        <Collections />
        <FloorBounties />
        <ShittyStickersCta />
        <UtilityInfo />
        <TopSales />
        <div>
          <TopUsersFeed />
        </div>
      </div>
    </>
  );
}
